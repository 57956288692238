import { ApiService } from '../../core/services/ApiService';
import { appState } from '../../core/state/AppState';
import { StorageService } from '../../core/services/StorageService';
import { TimingService } from '../../core/services/TimingService';
import { WordSequence } from './WordSequence';

export class TypingTest {
    constructor(container) {
        this.container = container;
        this.state = appState;
        this.wordSequence = new WordSequence(container);
        this.cursorStyle = StorageService.getItem('cursorStyle', 'underline');
        this.initialize();
    }

    async initialize() {
        try {
            this.wordSequence.showLoading();

            this.container.className = this.cursorStyle;
            const wordCount = StorageService.getItem('wordCount', 20);
            const words = await ApiService.getNgramTrainingPhrase(wordCount);

            console.log(words)
            if (!Array.isArray(words) || words.length === 0) {
                console.log('not an array')
                throw new Error('No words received from server');
            }

            const sequence = words.join(' ');

            this.state.setState({
                wordSequence: sequence,
                currentIndex: 0,
                startTime: null,
                timingData: [],
                characterTimings: {},
                ngramTimings: { bigrams: {}, trigrams: {} }
            });

            this.wordSequence.display(sequence);
            this.wordSequence.updateCursor(0);
            this.bindEvents();
        } catch (error) {
            console.error('Failed to initialize typing test:', error);
            this.wordSequence.showError(
                'Failed to load typing test. Please check your connection and try again.'
            );
        }
    }

    async generateSequence(wordCount) {
        try {
            const words = await Api.getNgramTrainingPhrase(wordCount);
            return words.join(' ');
        } catch (error) {
            console.error('Error generating sequence:', error);
            return '';
        }
    }

    bindEvents() {
        document.addEventListener('keydown', this.handleTyping.bind(this));
    }

    handleTyping(event) {
        const { wordSequence, currentIndex, startTime } = this.state;

        if (!startTime && event.key !== 'Backspace') {
            this.state.setState({ startTime: new Date() });
        }

        if (event.key === 'Backspace') {
            this.handleBackspace();
            return;
        }

        if (currentIndex < wordSequence.length && event.key.length === 1) {
            this.handleCharacterTyping(event.key);
        }
    }

    handleBackspace() {
        const { currentIndex, timingData, characterTimings, ngramTimings } = this.state;
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            const char = this.state.wordSequence[newIndex];

            const newTimingData = [...timingData];
            newTimingData[newIndex] = null;

            if (characterTimings[char] && characterTimings[char].length > 0) {
                characterTimings[char].pop();
            }

            if (newIndex > 0) {
                const bigram = this.state.wordSequence.slice(newIndex - 1, newIndex + 1);
                if (ngramTimings.bigrams[bigram] && ngramTimings.bigrams[bigram].length > 0) {
                    ngramTimings.bigrams[bigram].pop();
                }
            }
            
            if (newIndex > 1) {
                const trigram = this.state.wordSequence.slice(newIndex - 2, newIndex + 1);
                if (ngramTimings.trigrams[trigram] && ngramTimings.trigrams[trigram].length > 0) {
                    ngramTimings.trigrams[trigram].pop();
                }
            }
            this.state.setState({ 
                currentIndex: newIndex,
                timingData: newTimingData,
                characterTimings,
                ngramTimings
            });
            this.wordSequence.removeChar(newIndex, false);
            this.wordSequence.updateCursor(newIndex);
        }
    }

    handleCharacterTyping(typedChar) {
        const { wordSequence, currentIndex, timingData } = this.state;
        const isCorrect = typedChar === wordSequence[currentIndex];

        // Start timing for the next character
        const newTimingData = [...timingData];
        newTimingData[currentIndex] = { startTime: new Date() };

        this.state.setState({ timingData: newTimingData });
        
        this.recordTiming(currentIndex);
        this.wordSequence.updateChar(currentIndex, isCorrect);

        const newIndex = currentIndex + 1;
        this.state.setState({ currentIndex: newIndex });
        this.wordSequence.updateCursor(newIndex);

        if (newIndex >= wordSequence.length) {
            this.finalizeTest();
        }
    }

    recordTiming(index) {
        const { timingData, wordSequence } = this.state;
        const endTime = new Date();
        const timing = timingData[index];

        if (timing?.startTime) {
            const duration = endTime - timing.startTime;
            TimingService.recordCharacterTiming(
                wordSequence[index],
                duration,
                this.state.characterTimings
            );

            if (index > 0) {
                const bigram = wordSequence.slice(index - 1, index + 1);
                TimingService.recordNgramTiming(
                    bigram,
                    duration,
                    this.state.ngramTimings.bigrams
                );
            }

            if (index > 1) {
                const trigram = wordSequence.slice(index - 2, index + 1);
                TimingService.recordNgramTiming(
                    trigram,
                    duration,
                    this.state.ngramTimings.trigrams
                );
            }
        }
    }

    finalizeTest() {
        const stats = TimingService.calculateStatistics(this.state.characterTimings);
        const ngramStats = this.calculateNgramStatistics();
        const endTime = new Date();
        const duration = (endTime - this.state.startTime) / 1000;

        const statsRecord = {
            timestamp: new Date().toISOString(),
            stats,
            ngramStats,
            duration
        };

        const historicalStats = StorageService.getItem('historicalStats', []);
        historicalStats.push(statsRecord);
        StorageService.setItem('historicalStats', historicalStats);

        window.location.href = 'stats.html';
    }

    calculateNgramStatistics() {
        const { ngramTimings } = this.state;
        return {
            bigrams: TimingService.calculateStatistics(ngramTimings.bigrams),
            trigrams: TimingService.calculateStatistics(ngramTimings.trigrams)
        };
    }
}
