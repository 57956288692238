export class WordSequence {
    constructor(container) {
        this.container = container;
        this.element = null;
        this.initialize();
    }

    initialize() {
        this.element = document.createElement('div');
        this.element.className = 'word-sequence';
        this.container.appendChild(this.element);
    }

    showLoading() {
        this.element.innerHTML = `
            <div class="loading">
                Loading typing test...
            </div>
        `;
    }

    showError(message) {
        this.element.innerHTML = `
            <div class="error-message">
                ${message}
                <button onclick="window.location.reload()">Try Again</button>
            </div>
        `;
    }

    display(sequence) {
        if (!sequence) {
            this.showError('No text available for typing test.');
            return;
        }

        this.element.innerHTML = sequence
            .split('')
            .map((char, index) => `<span class="not-typed" id="char-${index}">${char}</span>`)
            .join('');
    }

    display(sequence) {
        this.element.innerHTML = sequence
            .split('')
            .map((char, index) => `<span class="not-typed" id="char-${index}">${char}</span>`)
            .join('');
    }

    updateChar(index, isCorrect) {
        const charElement = document.getElementById(`char-${index}`);
        if (charElement) {
            charElement.classList.remove('not-typed');
            charElement.classList.add(isCorrect ? 'correct' : 'incorrect');
        }
    }

    removeChar(index) {
        const charElement = document.getElementById(`char-${index}`);
        if (charElement) {
            charElement.classList.add('not-typed');
            charElement.classList.remove('correct', 'incorrect');
        }
    }

    updateCursor(index) {
        const chars = this.element.querySelectorAll('span');
        chars.forEach(char => char.classList.remove('current'));
        if (chars[index]) {
            chars[index].classList.add('current');
        }
    }
}
