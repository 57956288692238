export class AppState {
    constructor() {
        this.wordSequence = "";
        this.currentIndex = 0;
        this.startTime = null;
        this.timingData = [];
        this.characterTimings = {};
        this.ngramTimings = {
            bigrams: {},
            trigrams: {}
        };
        this.observers = new Set();
    }

    setState(newState) {
        Object.assign(this, newState);
        this.notifyObservers();
    }

    subscribe(observer) {
        this.observers.add(observer);
        return () => this.observers.delete(observer);
    }

    notifyObservers() {
        this.observers.forEach(observer => observer(this));
    }
}

export const appState = new AppState();
