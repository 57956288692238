export class TimingService {
    static recordCharacterTiming(char, duration, timings) {
        if (!timings[char]) {
            timings[char] = [];
        }
        timings[char].push(duration);
    }

    static recordNgramTiming(ngram, duration, ngramTimings) {
        if (!ngramTimings[ngram]) {
            ngramTimings[ngram] = [];
        }
        ngramTimings[ngram].push(duration);
    }

    static calculateStatistics(timings) {
        const stats = {};
        for (const char in timings) {
            const durations = timings[char];
            const sum = durations.reduce((a, b) => a + b, 0);
            const sorted = [...durations].sort((a, b) => a - b);

            stats[char] = {
                average: sum / durations.length,
                median: sorted[Math.floor(sorted.length / 2)],
                min: Math.min(...durations),
                max: Math.max(...durations)
            };
        }
        return stats;
    }
}
