import { TypingTest } from '../../components/typing/TypingTest';
import { Navigation } from '../../components/common/Navigation';
import { StorageService } from '../../core/services/StorageService';

document.addEventListener('DOMContentLoaded', async () => {
    try {
        // Initialize navigation
        new Navigation();

        // Initialize typing test
        const container = document.getElementById('word-sequence');
        if (!container) {
            throw new Error('Word sequence container not found');
        }
        
        new TypingTest(container);
        
        // Apply customization
        const customization = StorageService.getCustomization();
        applyCustomization(customization);
    } catch (error) {
        console.error('Failed to initialize application:', error);
    }
});

function applyCustomization(settings) {
    const style = document.documentElement.style;
    Object.entries(settings).forEach(([key, value]) => {
        if (value) {
            style.setProperty(`--${key.replace(/([A-Z])/g, '-$1').toLowerCase()}`, value);
        }
    });
}

