export class StorageService {
    static getItem(key, defaultValue = null) {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : defaultValue;
        } catch (error) {
            console.error(`Storage Error: ${error.message}`);
            return defaultValue;
        }
    }

    static setItem(key, value) {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error(`Storage Error: ${error.message}`);
        }
    }

    static getCustomization() {
        return {
            correctColor: this.getItem('correctColor', '#00ff00'),
            incorrectColor: this.getItem('incorrectColor', '#ff0000'),
            notTypedColor: this.getItem('notTypedColor', '#808080'),
            backgroundColor: this.getItem('backgroundColor', '#ffffff'),
            textColor: this.getItem('textColor', '#000000'),
            cursorStyle: this.getItem('cursorStyle', 'underline'),
            wordCount: this.getItem('wordCount', 20)
        };
    }
}
