export class ApiService {
    static async request(endpoint, options = {}) {
        try {
            const response = await fetch(`/api/${endpoint}`, {
                ...options,
                headers: {
                    'Content-Type': 'application/json',
                    ...options.headers
                }
            });
            if (!response.ok) {
                throw new Error(`API Error: ${response.statusText}`);
            }
            return await response.json();
        } catch (error) {
            console.error(`API Error: ${error.message}`);
            throw error;
        }
    }

    static async getDictionary() {
        return this.request('dictionary');
    }

    static async getRandomPhrase(wordCount) {
        return this.request(`dictionary/random?count=${wordCount}`);
    }

    static async getNgramTrainingPhrase(wordCount) {
        return this.request(`dictionary/ngram-training?count=${wordCount}`);
    }
}
