export class Navigation {
    constructor() {
        this.bindEvents();
    }

    bindEvents() {
        const settingsBtn = document.getElementById('settings-btn');
        if (settingsBtn) {
            settingsBtn.addEventListener('click', () => {
                window.location.href = 'customize.html';
            });
        }

        const statisticsBtn = document.getElementById('statistics-btn');
        if (statisticsBtn) {
            statisticsBtn.addEventListener('click', () => {
                window.location.href = 'stats.html';
            });
        }

        const backBtn = document.getElementById('back-btn');
        if (backBtn) {
            backBtn.addEventListener('click', () => {
                window.location.href = 'index.html';
            });
        }

        const siteName = document.querySelector('.site-name');
        if (siteName) {
            siteName.style.cursor = 'pointer';
            siteName.addEventListener('click', () => {
                window.location.href = 'index.html';
            });
        }
    }
}
